<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Cadastro de projetos</h1>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section box-opt">
            <h2>Qual o tipo de projeto?</h2>
            <v-layout wrap class="mt-5" text-xs-center column>
              <v-flex sm6 xs12>
                <v-btn
                  flat
                  style="width:268px"
                  round
                  color="white"
                  class="btn-primary px-4 mt-3"
                  large
                  @click="setProject(projectTypes.ProjetoSemIncentivoFiscal)"
                >{{getProjectTypeName(projectTypes.ProjetoSemIncentivoFiscal)}}</v-btn>
              </v-flex>
              <v-flex sm6 xs12>
                <!-- <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-4 mt-3"
                  large
                  @click="setProject(projectTypes.ProjetoIncentivado)"
                >{{getProjectTypeName(projectTypes.ProjetoIncentivado)}}</v-btn>-->
                <button
                  class="btn-donation-cart btn-primary px-4 mt-3"
                  @click="setProject(projectTypes.ProjetoIncentivado)"
                  style="width:268px"
                >{{getProjectTypeName(projectTypes.ProjetoIncentivado)}}</button>
              </v-flex>
              <v-flex sm6 xs12>
                <v-btn
                  flat
                  style="width:268px"
                  round
                  color="white"
                  class="btn-primary px-4 mt-3"
                  large
                  @click="setProject(projectTypes.Ticket)"
                >{{getProjectTypeName(projectTypes.Ticket)}}</v-btn>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>

      <div class="box-help-spacer"></div>
      <HelpBox :text="text"></HelpBox>
    </v-layout>
  </div>
</template>

<script type="plain/text">
import HelpBox from "@/components/HelpBox.vue";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import Project from "@/scripts/models/project.model";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    HelpBox
  },
  props: ["pageOptions"],
  created() {
    this.institutionId = this.$route.query.institutionId;
    this.pageOptions.changeClass(null);
    this.pageOptions.currentPage = 0;
    this.$emit("changeLevels", this.breadcrumbLevels);
  },
  data() {
    return {
      institutionId: null,
      propProject: new Project(),
      text: HelpBoxText.ProjectType,
      projectTypes: ProjectTypes,
      getProjectTypeName: GetProjectTypeName,
      projectValidationStatusIndex: 0,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { text: "Cadastro de Projetos" }
      ]
    };
  },
  computed: {
    ...mapGetters(["newProject"])
  },
  methods: {
    ...mapActions(["setNewProject"]),
    setProject(projectType) {
      if (this.newProject) {
        this.propProject.institutionId = this.institutionId == null ? this.newProject.institutionId : this.institutionId;
      } else {
        this.propProject.institutionId = this.institutionId;
      }
      this.propProject.projectTypeId = projectType;
      this.$set(this.propProject.stepsValidationStatus, "step0", true);
      this.setNewProject(this.propProject);

      this.$router.push({
        name: Routes.app.ProjectStep1
      });
    }
  }
};
</script>